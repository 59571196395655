import React from "react";
export default function ColorPicker({ colors, color, onColorChange }) {
  return (
    <div className="c-configurator__colors">
      {colors.map((item, index) => (
        <div className={`o-color o-color--${item} ${color === item ? ' o-color--active' : ''}`} key={index} style={{background: item}} onClick={() => onColorChange(item)} />
      ))}
    </div>
  );
}

import React, { Fragment, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorPicker from "../configurator/Picker"
import logo from "../images/datwyler.svg"
import Loadable from "react-loadable"
import Form from "../components/form"

const loader = () => <div>Loading ...</div>
//
// a component that will be defered to be rendered only on client side.
const MyLoadableThreejs = Loadable({
  loader: () => import("../configurator/Viewer"), // imports the component with the three.js and allows use of it safely
  loading: loader,
})

export function Configuration({
  capLabel,
  discLabel,
  finishing,
  index,
  onDelete,
}) {
  return (
    <div className="o-configuration">
      <h2>
        Configuration <span>#{index + 1}</span>
      </h2>

      <div className="o-configuration__item">
        <div>Aluminium seal’s color:</div>
        <div>{capLabel}</div>
      </div>
      <div className="o-configuration__item">
        <div>Plastic cap’s color:</div>
        <div>{discLabel}</div>
      </div>
      <div className="o-configuration__item">
        <div>Finish:</div>
        <div>{finishing}</div>
      </div>
      <div className="o-configuration__item">
        <button className="o-link" onClick={() => onDelete(index)}>
          Remove this configuration
        </button>
      </div>
    </div>
  )
}

const IndexPage = () => {
  const capColors = ["#A7ADAF", "#A57F40", "#931110", "#227448", "#152867"]
  const capLabels = [
    "Clear 001",
    "Gold 002",
    "Red 003",
    "Green 004",
    "Blue 005",
  ]
  const discColors = [
    "transparent",
    "#F9F9F9",
    "#DFD1A7",
    "#FBDD40",
    "#FFCD00",
    "#FFA300",
    "#FF8F1C",
    "#FF6A13",
    "#FC4C02",
    "#DA291C",
    "#B7312C",
    "#971B2F",
    "#7D2248",
    "#5E2751",
    "#EAA794",
    "#F2C6CF",
    "#F890A5",
    "#D5C3F3",
    "#8B84D7",
    "#7D55C7",
    "#009CDE",
    "#005EB8",
    "#0057B8",
    "#14254D",
    "#91D6AC",
    "#2CD5A9",
    "#64CCC9",
    "#008C95",
    "#78BE20",
    "#546223",
    "#00965E",
    "#00594F",
    "#8D3F2B",
    "#693F23",
    "#4F2C1D",
    "#BEC6C4",
    "#BBBCBC",
    "#888B8D",
    "#53565A",
    "#000000",
  ]
  const discLabels = [
    "Transparent 6001",
    "White 6003",
    "Ivory 6116",
    "Yellow 6023",
    "Yellow 6038",
    "Orange 6063",
    "Orange 6008",
    "Orange 6005",
    "Orange 6029",
    "Red 6055",
    "Red 6036",
    "Maroon 6011",
    "Burgundy 6010",
    "Plum 6022",
    "Pink 6035",
    "Pink 6060",
    "Pink 6047",
    "Lilac 6052",
    "Purple 6056",
    "Purple 6014",
    "Blue 6016",
    "Blue 6018",
    "Blue 6034",
    "Blue 6043",
    "Green 6042",
    "Turquoise 6012",
    "Turquoise 6066",
    "Teal 6032",
    "Lime 6013",
    "Olive 6085",
    "Green 6007",
    "Green 6020",
    "Brown 6039",
    "Brown 6004",
    "Brown 6041",
    "Grey 6051",
    "Grey 6019",
    "Grey 6081",
    "Grey 6017",
    "Black 6006",
  ]

  const [capColor, setCapColor] = useState("#A7ADAF")
  const [capLabel, setCapLabel] = useState("Clear 001")
  const [discColor, setDiscColor] = useState("transparent")
  const [discLabel, setDiscLabel] = useState("Transparent 6001")
  const [finishing, setFinishing] = useState(0)
  const [viewMode, setViewMode] = useState("configuration")
  const [controls, setControls] = useState(false)
  const [configurations, setConfiguration] = useState(null) // Array of { discLabel, capLabel and finishing }
  const finishings = ["Glossy", "Matte"]

  function addConfiguration() {
    const value = Array.isArray(configurations) ? [...configurations] : []
    const newValue = {
      id: `${discLabel}${capLabel}${finishings[finishing]}`,
      discLabel,
      capLabel,
      finishing: finishings[finishing],
    }
    // Only add a configuration once
    const hasValue = value.filter(item => item.id === newValue.id)
    if (hasValue.length > 0) {
      return
    }

    value.push(newValue)
    setConfiguration(value)

    setViewMode("cart")
  }

  function removeConfiguration(index) {
    setConfiguration([
      ...configurations.slice(0, index),
      ...configurations.slice(index + 1),
    ])
    if (configurations.length === 0) {
      setViewMode("configuration")
    }
  }

  return (
    <Layout>
      <SEO title="Find the perfect seal for your unique product." />
      <header className={`c-header`}>
        <a href={`https://datwyler.com`} className={`c-logo`}>
          <img src={logo} alt={`Datwyler`} className={`c-logo__img`} />
        </a>
        <a className={`c-header__cta`} href={`mailto:genny.fiore@datwyler.com`}>
          Mail us
        </a>
      </header>
      <div className={`c-configurator`}>
        <div className={`c-configurator__display`}>
          <MyLoadableThreejs
            capColor={capColor}
            discColor={discColor}
            finishing={finishing}
          />
        </div>
        <div className={`c-configurator__settings`}>
          <div className={`c-configurator__wrapper`}>
            {viewMode === "form" && (
              <Form
                configuration={configurations}
                closeHandler={() => setViewMode("configuration")}
              />
            )}
            {viewMode === "cart" && (
              <div className="c-form">
                <h1>
                  Your <strong>shopping cart</strong>
                </h1>
                <p className={`p--intro`}>
                  You can put three configurations in your order.
                </p>

                <div>
                  {configurations.map((item, idx) => (
                    <Configuration
                      key={item.id}
                      onDelete={() => removeConfiguration(idx)}
                      index={idx}
                      {...item}
                    />
                  ))}
                </div>
                <div style={{ marginBottom: 10 }}>
                  <button
                    className={`c-button`}
                    onClick={() => setViewMode("form")}
                  >
                    Complete your order
                  </button>
                </div>
                <div>
                  {configurations.length < 3 && (
                    <button
                      className={`c-button`}
                      onClick={() => setViewMode("configuration")}
                    >
                      Add new configuration
                    </button>
                  )}
                </div>
              </div>
            )}
            {viewMode === "configuration" && (
              <Fragment>
                <div className="c-configurator__intro">
                  <h1>
                    Design <strong>Your Seal</strong>{" "}
                    <span>
                      By <img src={logo} title={`Datwyler`} alt={`Datwyler`} />
                    </span>{" "}
                  </h1>
                  <p className={`p--intro`}>
                    Datwyler’s Design Your Seal configurator allows you to
                    create your own unique sealing solution. Select from{" "}
                    <strong>5 colors for your aluminum cap</strong>, made with
                    best-in-class alloy, and{" "}
                    <strong>40 colors for the plastic disc</strong>.<br />
                    <br />
                    Mix and match the colors to design the perfect seal for your
                    product. For more design options, contact your local
                    Datwyler representative or send us an{" "}
                    <a href={`mailto:genny.fiore@datwyler.com`}>email</a>.
                  </p>
                </div>

                <div className={`c-configurator__controls`}>
                  <button
                    className={`c-button c-configurator__controls--button`}
                    onClick={() => setControls(!controls)}
                  >
                    Design your seal
                  </button>
                  <div
                    className={`c-configurator__controls-wrapper ${
                      controls
                        ? "c-configurator__controls-wrapper--active "
                        : ""
                    }`}
                  >
                    <div
                      className={`c-configurator__item c-configurator__item-seperator`}
                    >
                      <h2>
                        Aluminum seal's color: <span> {capLabel}</span>
                      </h2>
                      <ColorPicker
                        colors={capColors}
                        color={capColor}
                        r={25}
                        onColorChange={color => {
                          setCapColor(color)
                          setCapLabel(capLabels[capColors.indexOf(color)])
                        }}
                      />
                    </div>

                    <div
                      className={`c-configurator__item c-configurator__item--colors  c-configurator__item-seperator`}
                    >
                      <h2>
                        Plastic cap's color:{" "}
                        <span className="color-name"> {discLabel}</span>
                      </h2>
                      <p className={`c-configurator__info`}>
                        Due to monitor differences, actual colors may vary
                        slightly from what appears online.
                      </p>
                      <ColorPicker
                        colors={discColors}
                        color={discColor}
                        r={25}
                        onColorChange={color => {
                          setDiscColor(color)
                          setDiscLabel(discLabels[discColors.indexOf(color)])
                        }}
                      />
                    </div>

                    <div className={`c-configurator__item`}>
                      <h2>
                        Finish:{" "}
                        <span className="color-name">
                          {" "}
                          {finishings[finishing]}
                        </span>
                      </h2>

                      <div className="finishing">
                        <div className="o-radio">
                          {finishings.map((item, id) => (
                            <>
                              <input
                                type="radio"
                                value={id}
                                id={item}
                                checked={finishing === id}
                                onChange={v => setFinishing(id)}
                              />
                              <label htmlFor={item}>{item}</label>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            <footer className={`c-footer`}>
              <ul className={`c-footer__list`}>
                <li className={`c-footer__list-item`}>
                  <a
                    className={`c-footer__list-link`}
                    href={`https://www.datwyler.com`}
                  >
                    Datwyler.com
                  </a>
                </li>
                <li className={`c-footer__list-item`}>
                  <a
                    className={`c-footer__list-link`}
                    href={`https://www.datwyler.com/en/privacy-policy/`}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </footer>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
